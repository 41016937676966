#home {
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: repeat;

  // disable the default padding styles
  .app__wrapper {
    padding: 0;
  }
}

.app__header {
  flex: 1;
  width: 100%;
  height: 100%;
  flex-direction: row;

  padding: 6rem 2rem 0rem;

  @media screen and (min-width: 2000px) {
    padding-top: 8rem;
  }

  @media screen and (max-width: 1200px) {
    flex-direction: column;
    margin-top: -1rem;
  }

  @media screen and (max-width: 764px) {
    padding: 6rem 1rem 2rem;
    margin-top: -10rem;
  }
}

.app__header-info {
  flex: 0.3;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;

  margin: 0 2rem;

  @media screen and (min-width: 1200px) {
    flex: 0.5;
  }

  @media screen and (max-width: 1200px) {
    width: 100%;
    margin-right: 0rem;
    justify-content: flex-end;
  }

}

.app__header-badge {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
  
  @media screen and (max-width: 1200px) {
    align-items: center;
  }

  .caption {
    padding: 1rem 0.5rem 1rem 2rem;
    width: auto;
    color: var(--gray-color);
    font-size: 1.6rem;
    margin-top: 300px;
    font-weight: 600;

    @media screen and (max-width: 1200px) {
      padding: 1rem 1rem;
      font-size: 2rem;
    }

    @media screen and (max-width: 500px) {
      padding: 1rem 1rem;
      font-size: 1.5rem;
    }

  }

  .method {
    padding: 1rem 0.5rem 1rem 2rem;
    display:flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    width: auto;
    color: var(--gray-color);
    font-size: 1.2rem;
    font-weight: 400;

    @media screen and (max-width: 1200px) {
      padding: 0rem 1rem;
      font-size: 1.5rem;
    }

    @media screen and (max-width: 500px) {
      padding: 1rem 1rem;
      font-size: 1.1rem;
      align-items: center;
    }
  }

  span {
    font-size: 2.5rem;

    @media screen and (min-width: 2000px) {
      font-size: 5rem;
    }
  }
}

.app__header-img {
  flex: 1;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: flex-end;
  position: relative;

  img {
    width: 95%;
    object-fit: contain;
    z-index: 1;
  }

  .landing{
    margin-bottom: 20px;
    filter: brightness(150%);

    @media screen and (min-width: 1200px) {
      width: 92%;
    }
  }

  @media screen and (max-width: 1200px) {
    align-items: flex-start;
    margin-top: 0rem;
    margin: 2rem 0;
  }

}
