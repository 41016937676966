.react-images__footer__count.react-images__footer__count--isModal{
    display: none;
}

.react-images__blanket.css-16jr3vd.css-1ycyyax{
    background-color: rgba(0, 0, 0, 0.91);
}

.react-images__footer.react-images__footer--isModal{
    justify-content: center;
    font-family:  "Roboto", sans-serif;
    font-size: 1.5em;
    font-weight: 500;
    color: var(--white-color);
  }
  
  img.react-images__view-image.react-images__view-image--isModal{
    filter: brightness(100%);
  }

  img.react-images__view-image.landscape{
    width: 95vw;
    height: auto;
    @media screen and (min-aspect-ratio: 8/5) {
      width: auto;
      height: 95vh;
    }
  }

  img.react-images__view-image.portrait{
    width: auto;
    height: 95vh;
    @media screen and (max-aspect-ratio: 5/7) {
      width: 95vw;
      height: auto;
    }
  }