.app__about {
  flex: 1;
  width: 100%;
  flex-direction: column;
}

.app__profiles {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: 2rem;
}

ul{
  list-style: disc outside none;
  padding-left: 1em;
  margin-top: 1.1em;
}

.app__profile-item {
  width: 190px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin: 2rem;

  img {
    width: 100%;
    height: 170px;
    border-radius: 15px;
    object-fit: cover;
  }

  @media screen and (max-width: 450px) {
    margin-top: -10px;
  }

  @media screen and (min-width: 2000px) {
    width: 370px;
    margin: 1rem 4rem;

    img {
      height: 320px;
    }
  }
}

.app__about-filter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;

  margin: 1rem 0;

  .app__about-filter-item {
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    background-color: #fff;
    color: #000;
    font-weight: 800;

    cursor: pointer;
    transition: all 0.3s ease;
    margin: 0.5rem;

    &:hover {
      background-color: var(--lightgray-color);
      color: #fff;
    }

    @media screen and (min-width: 2000px) {
      padding: 1rem 2rem;
      border-radius: 0.85rem;
    }
    @media screen and (max-width: 450px) {
      margin: 0rem;
    }
  }

  .item-active {
    background-color: var(--lightgray-color);
    color: #fff;
  }
  @media screen and (max-width: 450px) {
    margin: 1rem 0 0;
  }
}

.app__about-item{
  border-radius: 0.5rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  margin: 0 2rem;

  div{
    padding:2rem 2rem 4rem 2rem;
    color: var(--gray-color);
    p{
      margin-bottom:1rem;
      line-height: 1.5rem;
    }
    li{
      margin-bottom:0.5rem;
    }
  }
}
